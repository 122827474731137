export default {
  selectors: {
    base: '.batcom-languagenavigation',
    toggle: '.batcom-languagenavigation__toggle',
    langNav: '.cmp-languagenavigation',
    langNavGroup: '.cmp-languagenavigation__group',
    topLangNavGroup: '.cmp-languagenavigation > .cmp-languagenavigation__group',
    languageButton: '.batcom-languagenavigation__languagebutton',
    navigationCorp: '.cmp-navigationcorp',
    searchMenu: '.cmp-search__menu',
    navigationMenu: '.cmp-navigation',
    validatedAgeGate: '.cmp-batcom-agegate.batcom-hide',
  },
  classes: {
    open: 'open',
    visible: 'visible',
    hide: 'hide',
    noScroll: 'noScroll',
    langNavHeader: 'batcom-languagenavigation--header',
  },
};
