import { register } from '@netcentric/component-loader';
import config from './batcom-languagenavigation.config';
import { isOnMobileOrTablet, isOnDesktop, addBodyScroll, removeBodyScroll } from '../../../commons/utils';
import * as Events from '../../../commons/constants/events';

class LanguageNavigation {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.constValues = config.constValues;
    this.init();
  }

  init() {
    this.setRefs();
    if (this.langNav) {
      this.setPubSubListeners();
      this.setEventListeners();
      this.setWindowResizeObserver();

      if (this.topLangNavGroup) {
        this.handleFlyoutDisplay();
      }
    }
  }

  setRefs() {
    this.toggle = this.el.querySelector(this.selectors.toggle);
    this.langNav = this.el.querySelector(this.selectors.langNav);
    this.langNavGroups = this.el.querySelectorAll(this.selectors.langNavGroup);
    this.topLangNavGroup = this.el.querySelector(this.selectors.topLangNavGroup);
    this.searchMenu = document.querySelector(this.selectors.searchMenu);
    this.navigationMenu = document.querySelector(this.selectors.navigationMenu);
    this.corpNavigationMenu = document.querySelector(this.selectors.navigationCorp);
    this.validatedAgeGate = document.querySelector(this.selectors.validatedAgeGate);
  }

  setEventListeners() {
    if (this.toggle) {
      this.toggle.addEventListener('click', () => {
        this.toggleMenu();

        if (isOnMobileOrTablet()) {
          this.handleBodyScroll();
        }
      });

      const lastItem = this.topLangNavGroup.lastElementChild;

      lastItem.addEventListener('focusout', () => {
        this.toggleMenu();
      });
    }
    window.addEventListener('keyup', e => this.closeLangNavByKeyboard(e));
  }

  setPubSubListeners() {
    window.PubSub.subscribe(Events.TOGGLE_LANGUAGE_NAVIGATION, () => {
      this.toggleMenu();
    });

    window.PubSub.subscribe(Events.CLOSE_LANGUAGE_NAVIGATION, () => {
      this.closeMobileMenu();
    });

    window.PubSub.subscribe(Events.OPEN_CORP_NAVIGATION, () => {
      if (this.langNav.classList.contains(this.classes.open)) {
        this.toggleLangNav();
        this.closeMobileMenu();
      }
    });
  }

  setWindowResizeObserver() {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(() => {
        if (isOnDesktop()) {
          this.closeMobileMenu();
          if (this.validatedAgeGate) {
            removeBodyScroll(this.classes.noScroll);
          }
        }
      });
    });

    resizeObserver.observe(this.el);
  }

  closeMobileMenu() {
    this.langNav.classList.remove(this.classes.open);
    this.langNavGroups.forEach(group => {
      group.classList.remove(this.classes.visible);
    });
  }

  toggleMenu() {
    this.toggleLangNav();
    this.toggleLangNavGroups();

    if (this.searchMenu && this.searchMenu.classList.contains(this.classes.open)) {
      window.PubSub.publish(Events.CLOSE_SEARCH);
    }

    if ((this.navigationMenu && this.navigationMenu.classList.contains(this.classes.visible)) ||
      (this.corpNavigationMenu && this.langNav.classList.contains(this.classes.open))) {
      window.PubSub.publish(Events.CLOSE_NAVIGATION);
    }
  }

  toggleLangNav() {
    if (this.langNav.classList.contains(this.classes.open)) {
      this.langNav.classList.remove(this.classes.open);
      this.toggle.classList.remove(this.classes.open);
    } else {
      this.langNav.classList.add(this.classes.open);
      this.toggle.classList.add(this.classes.open);
    }
  }

  toggleLangNavGroups() {
    this.langNavGroups.forEach(langnavgroup => {
      if (langnavgroup.classList.contains(this.classes.visible)) {
        langnavgroup.classList.remove(this.classes.visible);
      } else {
        langnavgroup.classList.add(this.classes.visible);
      }
    });
  }

  handleBodyScroll() {
    if (this.langNav.classList.contains(this.classes.open)) {
      addBodyScroll(this.classes.noScroll);
    } else {
      removeBodyScroll(this.classes.noScroll);
    }
  }

  handleFlyoutDisplay() {
    if (!this.hasSeveralLanguages()) {
      this.topLangNavGroup.classList.add(this.classes.hide);
    }
  }

  hasSeveralLanguages() {
    return this.topLangNavGroup.children.length > 1;
  }

  closeLangNavByKeyboard(e) {
    if (this.langNav.classList.contains('open') && e.keyCode === 27) {
      this.langNav.classList.remove('open');
    }
  }
}

register({ LanguageNavigation });
